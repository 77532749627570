import {BrowserRouter as Router, Route} from "react-router-dom";
import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
// import {LogIn} from "./components/login"
// import {SignUp} from "./components/signup"
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
// import { Signedin } from "./components/signedin";
// import { Request } from "./components/request";
// import { MyApp } from "./components/test";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [concepteventsdata, setConcepteventsdata] = useState({});
  useEffect(() => {
    setConcepteventsdata(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Route path="/" exact render = {(props) => (      
          <>     
            <Navigation />
            <Header data={concepteventsdata.Header} />
            <About data={concepteventsdata.About} />
            <Services data={concepteventsdata.Services} />
            <Gallery data={concepteventsdata.Gallery}/>
            <Testimonials data={concepteventsdata.Testimonials} />
            <Contact data={concepteventsdata.Contact} />  
          </>
        )} />
        {/* <Route path="/login">
          <LogIn/>
        </Route>
        <Route path="/signup">
          <SignUp/>
        </Route>
        <Route path='/signedin'>
          <Signedin/>
        </Route>
        <Route path='/request'>
          <Request/>
        </Route>
        <Route path='/test'>
          <MyApp/>
        </Route> */}
      </div>
    </Router>
  );
};

export default App;
