import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <style>{`
        .portfolio-items {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .image-wrapper {
          position: relative;
          overflow: hidden;
          width: 400px;   /* Increased width */
          height: 300px;  /* Increased height */
          margin: 0px;
        }

        .image-wrapper img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      `}</style>
      <div className='container'>
        <div className='section-title'>
          <h2>Gallery</h2>
        </div>
        <div className='row portfolio-items'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <div className='image-wrapper'>
                    <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                  </div>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  );
}
